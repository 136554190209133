import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

export function* watcherInsuranceTypes() {
  yield takeLatest("INSURANCE_TYPES_REQUEST", getInsuranceTypes);
}

function fetchInsuranceTypes() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_URI_SERVER}/api/Public/Seguros/TipoSeguros`,
    params: {
      sitio:2
    }
  });
}

function* getInsuranceTypes() {
  try {
    const response = yield call(fetchInsuranceTypes);

    const data = response.data.map((dataAux) => {
      const { id, nombreTipoSeguro } = dataAux;
      const labelKey = id;
      const value = nombreTipoSeguro;
      const isSelected = true;
      return { labelKey, value, isSelected };
    });
    yield put({ type: "INSURANCE_TYPES_SUCCESS", data });
  } catch (error) {
    yield put({ type: "INSURANCE_TYPES_FAILURE", error });
  }
}
