import "../Styles/BeneficiosCondiciones.css";
import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";

const BeneficiosCondiciones = () => {
  React.useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <div data-aos="flip-left" id="bc">
      <div className="mt-4">
        <br />
        <h1 className="titulos">Beneficios de Sanatorio Seguro***</h1>
      </div>
      <br />
      <div id="bccard">
        <div className="container">
          <br />
          <br />
          <div className="bccontainer1">
            <div className="bcparrafos">
              <div className="bctexto">
                <h4>
                Copago Cero en <strong>consultas</strong> de<br/> urgencias **
                </h4>
              </div>
            </div>
            <div className="bcparrafos">
              <div className="bctexto">
                <h4>
                10% de descuento en <strong>farmacia</strong> **
                </h4>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="bccontainer2">
          <div className="bcparrafos">
              <div className="bctexto">
                <h4>
                15% de descuento en copago laboratorio e imágenes con tu Complementario Exámenes
                </h4>
              </div>
            </div>
            <div className="bcparrafos">
              <div className="bctexto">
                <h4>
                Copago Cero en 2 <strong>chequeos preventivos oftalmológicos</strong> al año con tu Seguro Alto Costo.
                </h4>
              </div>
            </div>
            <div className="bcparrafos">
              <div className="bctexto">
                <h4>
                Copago Cero en consultas de urgencia para padres y abuelos del titular con tu Seguro Accidentes
                </h4>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <br />
      <p className="container cfparrafo">
        *Beneficios exclusivos de nuestros seguros a través de IMED,{" "}
        <b>sin trámites.</b><br/>
      ** Beneficios según plan contratado.
      </p>
    </div>
  );
};

export default BeneficiosCondiciones;
