import '../Styles/ComoFunciona.css'
import img1 from "../imagenes/ComoFunciona/accorazon.png"
import img2 from "../imagenes/ComoFunciona/actratamiento.png"
import img3 from "../imagenes/ComoFunciona/actablero.png"
import img4 from "../imagenes/ComoFunciona/acmano.png"
import img6 from "../imagenes/ComoFunciona/acbici.png"
import ApiComoFunciona from './ApiComoFunciona'
import ApiComoFuncionaHospitalizado from './ApiComoFuncionaHospitalizado'
import ApiComoFuncionaExamenes from './ApiComoFuncionaExamenes'
import ApiComoFuncionaOncologico from './ApiComoFuncionaOncologico'
import Aos from "aos"
import "aos/dist/aos.css"
import React from 'react'

const ComoFunciona = () => {
  React.useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div id="cf">
      <div className=" mt-4 cfdivprin">
        <br />
        <h1 className="titulos">¿Cómo funciona Sanatorio Seguro?</h1>
        <h6 className="subtitulos">
          Te mostramos como funcionan cada tipo de cobertura según el plan que elijas
        </h6><br />
        <section data-aos="fade-up" className="container">
          <ul className="nav nav-tabs m-0" role="tablist" style={{ marginLeft: "1rem" }}>

            <li className="nav-item card"/*  onClick={() => document.getElementById('subCoberturaPlan').textContent = 'plan'} */>
              <a className="nav-link active cfcardtitulo" data-toggle="tab" href="#catastrofico" id="cfcardtitulo1">
                <div>
                  <h6 className="cftitulos">Alto Costo</h6>
                </div>
              </a>
            </li>
            <li className="nav-item card" /* onClick={() => document.getElementById('subCoberturaPlan').textContent = 'cobertura'} */>
              <a className="nav-link cfcardtitulo" data-toggle="tab" href="#complementario" id="cfcardtitulo2">
                <div>
                  <h6 className="cftitulos">Hospitalizado</h6>
                </div>
              </a>
            </li>
            <li className="nav-item card" /* onClick={() => document.getElementById('subCoberturaPlan').textContent = 'cobertura'} */>
              <a className="nav-link cfcardtitulo" data-toggle="tab" href="#examenes" id="cfcardtitulo4">
                <div>
                  <h6 className="cftitulos">Ambulatorio</h6>
                </div>
              </a>
            </li>
            <li className="nav-item card" /* onClick={() => document.getElementById('subCoberturaPlan').textContent = 'cobertura'} */>
              <a className="nav-link cfcardtitulo" data-toggle="tab" href="#accidentes" id="cfcardtitulo3">
                <div>
                  <h6 className="cftitulos">Accidentes</h6>
                </div>
              </a>
            </li>
            <li className="nav-item card" /* onClick={() => document.getElementById('subCoberturaPlan').textContent = 'plan'} */>
              <a className="nav-link cfcardtitulo" data-toggle="tab" href="#oncologico" id="cfcardtitulo5">
                <div>
                  <h6 className="cftitulos">Oncológico</h6>
                </div>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div id="catastrofico" className="container tab-pane active">
              <br />
              <div
                className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                id="cfcol"
              >
                <div className="col cfcolum">
                  <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                    <div>
                      <img src={img1} className="img-fluid" id="cfimg1" alt="" />
                    </div>
                    <div className="card-body">
                      <p>
                        Sientes un dolor en el pecho al respirar y te diriges a la Urgencia de Sanatorio Alemán, donde te diagnostican Neumonía.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col cfcolum">
                  <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                    <div>
                      <img src={img2} className="img-fluid" id="cfimg2" alt="" />
                    </div>
                    <div className="card-body">
                      <p>
                        El total de los gastos en la clínica es de $6.720.000 incluyendo honorarios médicos, insumos, hospitalización, exámenes y tratamientos.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col cfcolum">
                  <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                    <div>
                      <img src={img3} className="img-fluid" id="cfimg3" alt="" />
                    </div>
                    <div className="card-body">
                      <ApiComoFunciona />
                    </div>
                  </div>
                </div>
                <div className="col cfcolum">
                  <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                    <div>
                      <img src={img4} className="img-fluid" id="cfimg4" alt="" />
                    </div>
                    <div className="card-body">
                      <p>
                        Además desde el primer diagnóstico de tu enfermedad, Sanatorio Seguro Alto Costo te otorgará cobertura durante 3 años por el mismo diagnóstico.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ApiComoFuncionaHospitalizado />
            <ApiComoFuncionaExamenes />
            <ApiComoFuncionaOncologico />
            <div id="accidentes" className="container tab-pane fade">
              <br />
              <div
                className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                id="cfcol"
              >
                <div className="col cfcolum">
                  <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                    <div>
                      <img src={img6} className="img-fluid" id="cfimg3" alt="" />
                    </div>
                    <div className="card-body ">
                      <p>
                        Tuviste un accidente en bicicleta, vas a urgencias de Sanatorio Alemán, te diagnostican una lesión de ligamento.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col cfcolum">
                  <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                    <div>
                      <img src={img2} className="img-fluid" id="cfimg2" alt="" />
                    </div>
                    <div className="card-body">
                      <p>
                        El total de los gastos en la clínica es de $95.000, incluyendo honorarios médicos, insumos, exámenes y tratamientos.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col cfcolum">
                  <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                    <div>
                      <img src={img3} className="img-fluid" id="cfimg3" alt="" />
                    </div>
                    <div className="card-body">
                      <p>
                        Si tu Sistema de Salud Previsional cubre $23.750 (25%), queda una diferencia de $71.250 por pagar.<br /><br />
                        Al tener contratado Sanatorio Seguro Accidentes no existirá deducible asociado por lo que tendrás cubierto hasta un 100% de la diferencia por pagar si eres Isapre y 50% si eres Fonasa.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col cfcolum">
                  <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                    <div>
                      <img src={img4} className="img-fluid" id="cfimg1" alt="" />
                    </div>
                    <div className="card-body">
                      <p>
                        Sanatorio Seguro Accidentes entregará cobertura durante 1 año por el mismo diagnóstico en prestaciones hospitalarias o ambulatorias asociadas al accidente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="container cfparrafo">Puede aplicar tope máximo según tramo de edad y plan contratado.</p>
            <p className="container cfparrafo">Los valores son meramente referenciales.</p>
            <br />
          </div>
        </section>
      </div>
    </div>
  );
}

export default ComoFunciona

