import "../Styles/Footer.css";
import logo from "../imagenes/Home/SAlogo.svg";
import logoM from "../imagenes/Home/M-logo.svg";
import ApiUF from "./ApiUF";

const Footer = () => {
  return (
    <div id="ft">
        <br/>
        <div className="fdivlogos">
          <img src={logo} className="flogo1" alt="MetLife" />
          <img src={logoM} className="flogo2" alt="MetLife" />
        </div>
      <div id="ftlegal" className="fdivtextolegal  ">
        <br></br>
        <br></br>
        <p className="text-center cfparrafo"><b><ApiUF Tipo="2" /></b></p>
        <p className="text-center cfparrafo">
          <br></br>
            El riesgo es cubierto por MetLife Chile Seguros de Vida S.A., las
            condiciones generales de esta póliza se encuentran depositadas en la
            Comisión para el Mercado Financiero bajo los códigos POL 3 2019 0074
            Sanatorio Seguro Alto Costo, POL 3 2020 0059 Sanatorio Seguro
            Hospitalizado y Exámenes y POL 3 2021 0232 Sanatorio Seguro
            Accidentes. La presente información solo representa un resumen de
            las coberturas, condiciones y limitaciones de cada uno de los
            seguros antes mencionados. El detalle de las condiciones, términos y
            exclusiones, se encuentra en las Condiciones Generales antes
            mencionadas y en las Condiciones Particulares de la Póliza. ***Los
            beneficios adicionales mencionados, no constituyen cobertura, siendo
            de exclusiva responsabilidad de Sanatorio Alemán la prestación de
            los servicios convenidos, sin responsabilidad alguna para MetLife
            Chile Seguros de Vida S.A., quien podrá poner término a este
            Convenio en cualquier momento cuando así lo estime conveniente,
            informando oportunamente a los usuarios. Oferta exclusiva para
            personas naturales que residan en Chile.
        </p>
      </div>
      <input id="version" name="version" type="hidden" value={"V" + process.env.REACT_APP_VERSION} />
    </div>
  );
};
export default Footer;
