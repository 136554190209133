import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FAQs from "./FAQs";

function TodosnosPreguntan(props) {
  const dispatch = useDispatch();
  const [watchAllFAQs, setWatchAllFAQs] = useState(false);
  const insuranceFAQ = useSelector((state) => state.InsuranceFAQ);
  const onChangeWatchAllFAQs = () => {
    setWatchAllFAQs(!watchAllFAQs);
  };
  useEffect(() => {
    dispatch({
      type: "INSURANCE_FAQ_REQUEST",
    });
  }, [dispatch]);
  return (
    <>
      <div id="tnp">
        <h1 className="titulos mt-5">Lo que todos nos preguntan</h1>
        <div className="tnp">
          <br />
          <br />
          {!insuranceFAQ.fetching && insuranceFAQ.data && (
            <div className="container tnpaccordion">
              {insuranceFAQ.data.map((aux, index) => (
                <FAQs
                  key={index}
                  data={aux}
                  index={index}
                  watchAllFAQs={watchAllFAQs} />
              ))}
              <div className="accordion-item border-0" key="TNPX1">
                <h2 className="accordion-header" id="X1">
                  <button id="FAQX1" className={!watchAllFAQs ? "accordion-button btn btn-block collapsed tnpacc" : "accordion-button btn btn-block tnpacc"} onClick={onChangeWatchAllFAQs}>
                    <span className="tnpnombre morefaq">{!watchAllFAQs ? "Ver Más..." : "Ver Menos..."}</span>
                  </button>
                </h2>
              </div>
            </div>
          )}
          <br />
          <br />
        </div>
      </div>
    </>
  );
};
export default TodosnosPreguntan;
