import img2 from "../imagenes/ComoFunciona/actratamiento.png"
import img3 from "../imagenes/ComoFunciona/actablero.png"
import img4 from "../imagenes/ComoFunciona/acmano.png"
import img5 from "../imagenes/ComoFunciona/acestomago.png"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

function ApiComoFuncionaHospitalizado(props) {
  const dispatch = useDispatch();
  const insuranceUF = useSelector((state) => state.InsuranceUF);

  useEffect(() => {
    dispatch({
      type: "INSURANCE_UF_REQUEST",
    });
  }, [dispatch]);

  return (
    <div id="complementario" className="container tab-pane fade">
                <br />
                <div
                  className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                  id="cfcol"
                >
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                      <div>
                        <img src={img5} className="img-fluid" id="cfimg3" alt="" />
                      </div>
                      <div className="card-body ">
                        <p>
                          Sientes un dolor en el estómago y te diriges a la Urgencia de Sanatorio Alemán, donde te diagnostican Apendicitis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                      <div>
                        <img src={img2} className="img-fluid" id="cfimg2" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                          El total de los gastos en la clínica es de $6.150.000 incluyendo honorarios médicos, insumos, exámenes y tratamientos. Si tu Fonasa/Isapre cubre $2.767.500 (45%) queda una diferencia de $3.382.500 por pagar.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                      <div>
                        <img src={img3} className="img-fluid" id="cfimg3" alt=""/>
                      </div>
                      <div className="card-body">
                      <p>
                        Sanatorio Seguro Hospitalizado cubre hasta 50% de la diferencia $1.691.250.<br />
                        <br />
                        Si el deducible anual ya ha sido pagado, sólo tendrás que pagar $1.691.250. Si aún no lo has pagado, deberás sumar el deducible y tu monto final a pagar será de {!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(1691250+insuranceUF.data.value*2).toLocaleString("es-CL")}`}</span>)}.
                      </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                      <div>
                        <img src={img4} className="img-fluid" id="cfimg1" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                          Sanatorio Seguro Hospitalizado, cubre cualquier hospitalización que tengas durante 1 años, después de haber pagado el deducible, hasta que consumas el capital de UF 200 ({!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(insuranceUF.data.value*200).toLocaleString("es-CL")}`}</span>)}).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  );
}
export default ApiComoFuncionaHospitalizado;