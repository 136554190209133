import actratamiento from "../imagenes/ComoFunciona/actratamiento.png"
import img4 from "../imagenes/ComoFunciona/acmano.png"
import actablero from "../imagenes/ComoFunciona/actablero.png"
import accorazon from "../imagenes/ComoFunciona/accorazon.png"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

function ApiComoFuncionaOncologico(props) {
    const dispatch = useDispatch();
    const insuranceUF = useSelector((state) => state.InsuranceUF);

    useEffect(() => {
        dispatch({
            type: "INSURANCE_UF_REQUEST",
        });
    }, [dispatch]);

    return (<div id="oncologico" className="container tab-pane fade">
        <br />
        <div
            className="row row-cols-2 row-cols-md-4 mb-4 text-center"
            id="cfcol"
        >
            <div className="col cfcolum">
                <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                    <div>
                        <img src={accorazon} className="img-fluid" id="cfimg3" alt="" />
                    </div>
                    <div className="card-body ">
                        <p>
                            Te diagnostican cáncer en Sanatorio Alemán
                        </p>
                    </div>
                </div>
            </div>
            <div className="col cfcolum">
                <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                    <div>
                        <img src={actratamiento} className="img-fluid" id="cfimg2" alt="" />
                    </div>
                    <div className="card-body">
                        <p>
                            El total de los gastos de la primera etapa de terapia en la clínica es de $5.550.000 incluyendo honorarios médicos, insumos, hospitalización, exámenes y tratamientos.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col cfcolum">
                <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                    <div>
                        <img src={actablero} className="img-fluid" id="cfimg3" alt="" />
                    </div>
                    <div className="card-body">
                        <p>
                            Tu Sistema de Salud Previsional cubre $2.664.000 (48%), queda una diferencia de  $2.886.000 por pagar.<br /><br />
                            No tendrás que pagar deducible hasta antes de los 70 años, por lo que tendrás cubierto hasta el 100% del monto.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col cfcolum">
                <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                    <div>
                        <img src={img4} className="img-fluid" id="cfimg1" alt="" />
                    </div>
                    <div className="card-body">
                        <p>
                            Además contarás con una indemnización por UF 50 en el primer diagnóstico de cáncer.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default ApiComoFuncionaOncologico;
