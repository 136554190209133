import '../Styles/Inicio.css'
import logo from "../imagenes/Home/SAlogo.svg"
import { Nav, Navbar,} from 'react-bootstrap'

const Inicio = () => {

    return (
      <div id="inicio">
        <div className="card mt-4" id="barraenlace">
          <Navbar expand="lg" className="navbar fixed-top">
            <Navbar.Brand href="#">
              <div id="ilogo" className="animate__animated animate__bounce">
                <img src={logo} alt=""/>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="m-auto my-2 my-lg-0 inav animate__animated animate__bounce"
                navbarScroll>
                <Nav.Link href="#sr">NUESTROS PLANES</Nav.Link>
                <Nav.Link href="#cf">¿CÓMO FUNCIONA?</Nav.Link>
                <Nav.Link href="#bc">BENEFICIOS</Nav.Link>
                <Nav.Link href="#tnp">CONSULTAS FRECUENTES</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    );
}

export default Inicio
