import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

function ApiComoFunciona(props) {
  const dispatch = useDispatch();
  const insuranceUF = useSelector((state) => state.InsuranceUF);

  useEffect(() => {
    dispatch({
      type: "INSURANCE_UF_REQUEST",
    });
  }, [dispatch]);

  return (
    <div>
      Si tu Sistema de Salud Previsional cubre $2.975.000 (44%), queda una diferencia de $3.745.000 por pagar.<br /><br />
      Tu deberás pagar el deducible del plan que elijas. Si fuera de UF 25, serán {!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(insuranceUF.data.value*25).toLocaleString("es-CL")}`}</span>)}
      , después tendrás cubierto hasta el 100% del monto restante por pagar ({!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(3745000 - (insuranceUF.data.value*25)).toLocaleString("es-CL")}`}</span>)}).
    </div>    
  );
}
export default ApiComoFunciona;
