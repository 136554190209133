import img3 from "../imagenes/ComoFunciona/actablero.png"
import img4 from "../imagenes/ComoFunciona/acmano.png"
import img7 from "../imagenes/ComoFunciona/acdocumento.png"
import img8 from "../imagenes/ComoFunciona/acdoctor.png"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

function ApiComoFuncionaExamenes(props) {
  const dispatch = useDispatch();
  const insuranceUF = useSelector((state) => state.InsuranceUF);

  useEffect(() => {
    dispatch({
      type: "INSURANCE_UF_REQUEST",
    });
  }, [dispatch]);

  return (<div id="examenes" className="container tab-pane fade">
                <br />
                <div
                  className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                  id="cfcol"
                >
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                      <div>
                        <img src={img8} className="img-fluid" id="cfimg3" alt="" />
                      </div>
                      <div className="card-body ">
                        <p>
                          Si de forma posterior a una consulta médica tienes que realizarte exámenes en Sanatorio Alemán
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                      <div>
                        <img src={img7} className="img-fluid" id="cfimg2" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                          El total a pagar por concepto de exámenes de laboratorio e imágenes es de $75.000. Si tu Fonasa/Isapre cubre $30.000 (40%) queda una diferencia de $45.000 por pagar.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                      <div>
                        <img src={img3} className="img-fluid" id="cfimg3" alt=""/>
                      </div>
                      <div className="card-body">
                      <p>
                        Tu seguro contratado cubre hasta  $13.500 (30%) de la diferencia por pagar.<br /><br />
                        Si ya pagaste el deducible anual deberás pagar sólo $31.500 de la diferencia por pagar. Si aún no has pagado tu deducible deberás sumar UF 1 y tu total a pagar será de {!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(31500+insuranceUF.data.value).toLocaleString("es-CL")}`}</span>)}.
                      </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                      <div>
                        <img src={img4} className="img-fluid" id="cfimg1" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                          Sanatorio Seguro Ambulatorio, cubre cualquier exámenes que tengas durante 1 año, después de haber pagado el deducible, hasta que consumas el capital de UF 20 ({!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(insuranceUF.data.value*20).toLocaleString("es-CL")}`}</span>)}).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>    
  );
}
export default ApiComoFuncionaExamenes;
