import React, { useState, useEffect } from "react";
import "../Styles/SeccionRenata.css";
import renata from "../imagenes/SeccionRenata/Renata.png";
import { Button, Form } from "react-bootstrap";
import Planes from "./Planes";
import Comparator from "./Comparator";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomSpinner from "./CustomSpinner";
import BootstrapSelect from "react-bootstrap-select-dropdown";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import ReactTooltip from "react-tooltip";
import $ from "jquery";
import Container from "react-bootstrap/Container";
import ApiUF from "./ApiUF";

function SeccionRenata(props) {
  const dispatch = useDispatch();
  const insuranceTypes = useSelector((state) => state.InsuranceTypes);
  const insuranceByTypeInsurance = useSelector(
    (state) => state.InsuranceByTypeInsurance
  );
  const insuranceComparator = useSelector((state) => state.InsuranceComparator);
  const [amounts, setAmounts] = useState([1, 600000]);
  const [numPeople, setNumPeople] = useState(1);
  const [maxAge, setMaxAge] = useState(18);
  const [insuranceTypesSelect, setInsuranceTypesSelect] = useState(null);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [showPlans, setShowPlans] = useState(true);
  const [watchAll, setWatchAll] = useState(false);
  const [isLoadMultiple, setLoadMultiple] = useState(false);
  const onValueChange = (a) => {
    setAmounts(a);
  };

  const onChangeNumPeopleChange = (value) => {
    setNumPeople(value);
  };

  const onChangeMaxAgeChange = (value) => {
    setMaxAge(value);
  };

  const onChangeInsuranceTypesSelect = (e) => {
    setInsuranceTypesSelect(e.selectedKey);
    if (!isLoadMultiple) {
      $(".srselect button span").before(
        $(document.createElement("input")).prop({
          value: "check",
          class: "metCheck form-check-input btn actions-btn btn-primary",
          type: "checkbox",
        })
      );
      $(".bs-select-all").html("");
      $(".bs-deselect-all").html("");
      $(".bs-select-all").addClass("bi bi-check-square");
      $(".bs-deselect-all").addClass("bi bi-square");
      setLoadMultiple(true);
    }
    $(".metCheck").prop("checked", false);
    $(".selected .metCheck").prop("checked", true);
  };

  const onClickSelectedPlans = (id, value) => {
    let aux = selectedPlans;

    if (value) {
      aux.push(id);
    } else {
      aux.pop(id);
    }
    setSelectedPlans(aux);
  };

  const onChangeWatchAll = () => {
    setWatchAll(!watchAll);
  };

  useEffect(() => {
    dispatch({
      type: "INSURANCE_TYPES_REQUEST",
    });
  }, [dispatch]);

  useEffect(() => {
    setShowPlans(false);
  }, [insuranceComparator.data]);

  useEffect(() => {
    setShowPlans(true);
  }, [insuranceByTypeInsurance.data]);
  function VerMasBoton(longitud) {
    if (insuranceByTypeInsurance.data.length > 4) {
      return (
        <Row>
          <Button
            variant="outline-dark"
            onClick={onChangeWatchAll}
            style={{
              color: "#c8c8c8",
              fontSize: "1.5rem",
              fontWeight: "bold",
              border: "solid 3px",
            }}
          >
            {!watchAll ? "▼      Ver Más      ▼" : "▲      Ocultar      ▲"}
          </Button>
        </Row>
      );
    } else {
      return "";
    }
  }
  return (
    <div id="sr">
      <div>
        <div className="mt-4">
          <br />
          <h1 className="titulos">Elige el plan que se adapte a ti</h1>
          <h6 className="subtitulos">
            Existen combinaciones de coberturas para cada uno
          </h6>
          <br />
          <div id="srenata">
            <img src={renata} id="srimg" alt="Hola soy Claudia" />
          </div>
          <h1 className="titulos">Hola soy Claudia</h1>
          <h6 className="subtitulos">
            <strong>
              Simula con tus datos y selecciona un plan para avanzar en la
              contratación o pedir que te contacte alguien de mi equipo
            </strong>
          </h6>
          <br />
        </div>

        {!insuranceTypes.fetching && insuranceTypes.data && (
          <div className="container srform">
            <Row style={{ marginBottom: 20, alignItems: "flex-end", justifyContent:"center", marginRight:110 }}>
              <Col lg={2} className="mb-3 srnpersonas">
                <Row>
                  <Col>
                    <span data-tip data-for="srtooltip1">
                      <Form.Label
                        style={{
                          color: "grey",
                          paddingRight: 10,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {`¿Para\n cuántas personas? `}
                      </Form.Label>
                      <button type="button" className="btn position-relative">
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                          i
                        </span>
                      </button>
                    </span>

                    <ReactTooltip
                      id="srtooltip1"
                      type="info"
                      effect="solid"
                      backgroundColor="#ff4432"
                    >
                      Si es para ti solo, elige 1. <br />
                      Si no, elige la cantidad de personas
                      <br /> en tu grupo familiar incluyéndote.
                    </ReactTooltip>
                  </Col>
                </Row>
                <Row>
                  <Col className="  srnpersonas">
                    <CustomSpinner
                      type={"int"}
                      max={99}
                      min={1}
                      step={1}
                      onChange={onChangeNumPeopleChange}
                      size="sm"
                      arrows={false}
                      value={numPeople}
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={2} className="mb-3 srnedad">
                <Row>
                  <Col>
                    <span data-tip data-for="srtooltip2">
                      <Form.Label
                        style={{
                          color: "grey",
                          paddingRight: 10,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {" Edad del \n mayor del grupo"}
                      </Form.Label>

                      <button type="button" className="btn position-relative">
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                          i
                        </span>
                      </button>
                    </span>
                    <ReactTooltip
                      id="srtooltip2"
                      type="info"
                      effect="solid"
                      backgroundColor="#ff4432"
                    >
                      Si es para una sola persona,
                      <br /> indicar su edad.
                    </ReactTooltip>
                  </Col>
                </Row>
                <Row>
                  <Col className="  srnedad">
                    <CustomSpinner
                      type={"int"}
                      precision={2}
                      max={74}
                      min={18}
                      step={1}
                      onChange={onChangeMaxAgeChange}
                      size="sm"
                      value={maxAge}
                    />
                  </Col>
                </Row>
              </Col>
              {/* <Col lg={3} className="mb-3 srlabelvalormensual">
                <Row>
                  <Col sm={9}>
                    <span>
                      <Form.Label
                        style={{
                          color: "grey",
                          paddingRight: 2,
                          marginBottom: 45,
                          marginLeft: 15,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {` Valor mensual del seguro`}
                      </Form.Label>
                    </span>
                    <Col className="  srnvalormensual">
                      <CustomSlider
                        onChange={(values) => {
                          onValueChange(values);
                        }}
                        values={amounts}
                        min={5000}
                        max={300000}
                        labelStyle={{
                          color: "black",
                          backgroundColor: "#c8c8c8",
                        }}
                      />
                      <span data-tip data-for="srtooltip3"></span>
                    </Col>
                  </Col>
                  <Col sm={3}>
                    <a>
                    <div
                      style={{
                        backgroundColor: "#0061a0",
                        width: 30,
                        height: 30,
                        textAlign: "center",
                      }}
                    >
                      <div style={{ paddingTop: 5 }}>
                        <i
                          className="bi-bag-dash-fill"
                          style={{ color: "white", marginTop: 10 }}
                        ></i>
                      </div>
                    </div>
                      </a> 
                  </Col>
                </Row>
              </Col> */}
              <Col lg={3} className="mb-3">
                <Row>
                  <Col>
                    <Form.Label className="srlabelselect">
                      {"Tipo de seguro"}
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col className="srselect">
                    <BootstrapSelect
                      isMultiSelect
                      showAction={true}
                      options={insuranceTypes.data}
                      onChange={(e) => onChangeInsuranceTypesSelect(e)}
                      placeholder="Seleccione una o mas opciones"
                      actionBtnStyle="btn-primary"
                      selectStyle="btn-primary"
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={1} className="mb-3" style={{ marginLeft: "-5rem" }}>
                <Row>
                  <Col>
                    <div className="d-grid gap-2">
                      <Button
                        className="srbtncotiza"
                        variant="dark"
                        onClick={() => {
                          setSelectedPlans([]);
                          dispatch({
                            type: "INSURANCE_BYTYPE_INSURANCE_REQUEST",
                            params: {
                              numPersonas: numPeople,
                              edadMaxima: maxAge,
                              vmMaximo: amounts[1],
                              vmMinimo: amounts[0],
                              strTipoSeguro: insuranceTypesSelect.join(),
                            },
                          });
                        }}
                      >
                        {`Ver Planes`}
                        {insuranceByTypeInsurance.fetching && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}

        <Row>
          <Col style={{ textAlign: "center" }}>
            {(insuranceByTypeInsurance.fetching ||
              insuranceComparator.fetching) && (
              <React.Fragment>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  variant="primary"
                  color="red"
                />
                <p>Cargando...</p>
              </React.Fragment>
            )}
          </Col>
        </Row>

        {showPlans &&
          !insuranceByTypeInsurance.fetching &&
          insuranceByTypeInsurance.data && (
            <React.Fragment>
              {selectedPlans && (
                <Row className="mb-3 justify-content-center">
                  <Col xs={12} md={12} lg={4}>
                    <div
                      className="d-grid gap-2"
                      style={{ justifyContent: "center" }}
                    >
                      <Button
                        variant="dark"
                        style={{
                          color: "white",
                          borderColor: "#c8c8c8",
                          backgroundColor: "#c8c8c8",
                          width: "100%",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          dispatch({
                            type: "INSURANCE_COMPARATOR_REQUEST",
                            params: {
                              numPersonas: numPeople,
                              edadMaxima: maxAge,
                              vmMaximo: amounts[1],
                              vmMinimo: amounts[0],
                              strSeguros: selectedPlans.join(),
                            },
                          });
                        }}
                      >
                        {`Comparar planes `}
                        {insuranceComparator.fetching && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                {insuranceByTypeInsurance.data.map((aux, index) => (
                  <Col lg={3} md={6} xs={12}>
                    <Planes
                      key={index}
                      data={aux}
                      index={index}
                      watchAll={watchAll}
                      selectedList={selectedPlans}
                      numPeople={numPeople}
                      maxAge={maxAge}
                      amounts={amounts}
                      click={onClickSelectedPlans}
                    />
                  </Col>
                ))}
              </Row>
              <Row><p className="text-right cfparrafo"><b><ApiUF Tipo="3" /></b></p></Row>
              <VerMasBoton longitud={insuranceByTypeInsurance.data.length} />
            </React.Fragment>
          )}
        {!showPlans &&
          !insuranceComparator.fetching &&
          insuranceComparator.data && (
            <Container>
              <div
                style={{ textAlign: "right" }}
                onClick={() => {
                  setSelectedPlans([]);
                  dispatch({
                    type: "INSURANCE_BYTYPE_INSURANCE_REQUEST",
                    params: {
                      numPersonas: numPeople,
                      edadMaxima: maxAge,
                      vmMaximo: amounts[1],
                      vmMinimo: amounts[0],
                      strTipoSeguro: insuranceTypesSelect.join(),
                    },
                  });
                }}
              >
                <i data-tip="React-tooltip" data-for="srtooltipVolverPlan" style={{ fontSize: "30px" }} className="bi bi-x-lg cpbtncerrar">
                  <ReactTooltip id="srtooltipVolverPlan" place="right" type="info" effect="solid" backgroundColor="#ff4432">Volver</ReactTooltip>
                </i>
              </div>
              <Comparator data={insuranceComparator.data} />
              <div style={{ marginTop: "-1rem" }}>
                <p style={{ fontSize: "0.8rem" }}>
                  <em>*Aplican condiciones</em>
                </p>
              </div>
              <br />
              <br />
            </Container>
          )}
      </div>
      <br />
      <br />
    </div>
  );
}
export default SeccionRenata;
