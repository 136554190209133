import React from "react";
import "../Styles/SeccionRenata.css";
import "aos/dist/aos.css";
import Aos from "aos";

const CondicionesSeguros = () => {
  React.useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
 return (
  <>
    <div id="cs">
      <section data-aos="fade-up">
        <div className="container">
          <ul className="nav nav-tabs srul" role="tablist">
            <li className="nav-item card">
              <a
                className="nav-link active srcardtitulo"
                data-toggle="tab"
                href="#altocosto"
                id="bccardtitulo1"
              >
                <div>
                  <h6 className="srtitulos">Condiciones Alto Costo</h6>
                </div>
              </a>
            </li>
            <li className="nav-item card">
              <a
                className="nav-link srcardtitulo"
                data-toggle="tab"
                href="#hospitalarioexamenes"
                id="bccardtitulo2"
              >
                <div>
                  <h6 className="srtitulos">
                    Condiciones Hospitalario y Ambulatorias
                  </h6>
                </div>
              </a>
            </li>
            <li className="nav-item card">
              <a
                className="nav-link srcardtitulo"
                data-toggle="tab"
                href="#bcaccidentes"
                id="bccardtitulo3"
              >
                <div>
                  <h6 className="srtitulos">Condiciones Accidentes</h6>
                </div>
              </a>
            </li>
            <li className="nav-item card">
              <a
                className="nav-link srcardtitulo"
                data-toggle="tab"
                href="#concologico"
                id="bccardtitulo4"
              >
                <div>
                  <h6 className="srtitulos">Condiciones Oncológico</h6>
                </div>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div id="altocosto" className="container tab-pane active">
              <div className="bcparrafo col card">
                <p className="bcparrafo3">
                  <br />
                  - Debes tener una afiliación vigente con Sistema Previsional de Salud.
                  <br />
                  <br />
                  - Puedes contratar Sanatorio Seguro Alto Costo si eres mayor de 18 años y menor de 75 años.
                  <br />
                  <br />
                  - Para incorporar como carga a tu pareja (cónyuge/conviviente civil) debe también ser mayor de 18
                  años y menor a 70 años.
                  <br />
                  <br />
                  - Para incorporar como carga a tus hijos deben tener, al menos, 14 días de vida y ser menores de 23 años. No puedes
                  asegurar a tus padres o abuelos como cargas, pero ellos pueden contratar el seguro como titulares independientes.
                  <br />
                  <br />
                  - Tanto tú como tu cónyuge (en caso de ser carga) podrán permanecer de forma vitalicia, los hijos que sean carga podrán permanecer hasta antes de los 24 años, en la póliza familiar, pero podrían permanecer como titulares independientes. Esta condición se permite siempre que la póliza se encuentre vigente y el pago de las primas se encuentren al día.
                  <br />
                  <br />
                  - No cubre preexistencias.
                  <br />
                  <br />
                  - Debes completar una Declaración Personal de Salud para
                  poder avanzar en la contratación. Aplican exclusiones, que
                  puedes revisar en pie de página.
                  <br />
                  <br />- Incluye cobertura por ambulancia sin tope de
                  kilómetros ni evento, pero tiene cobertura de hasta UF 10.
                </p>
              </div>
            </div>
            <div
              id="hospitalarioexamenes"
              className="container tab-pane fade"
            >
              <div className="bcparrafo col card">
                <p className="srtitulop">
                  Aplica a Sanatorio Seguro Exámenes y Hospitalizado
                </p>
                <p className="bcparrafo3">
                  - Debes tener una afiliación vigente con Sistema Previsional
                  de Salud.
                  <br />
                  <br />
                  - Puedes contratar si eres mayor de 18 años y menor de 60
                  años.
                  <br />
                  <br />
                  - Para incorporar como carga a tu pareja
                  (cónyuge/conviviente civil) debe también ser mayor de 18
                  años y menor a 60 años.
                  <br />
                  <br />
                  - Para incorporar como carga a tus hijos deben tener, al
                  menos, 14 días de vida y ser menores de 23 años. No puedes
                  asegurar a tus padres o abuelos como cargas, pero ellos
                  pueden contratar el seguro como titulares independientes.
                  <br />
                  <br />
                  - Tanto tú como tu cónyuge (en caso de ser carga) podrán
                  permanecer hasta antes de cumplir 65 años, los hijos que
                  sean carga podrán permanecer hasta antes de los 24 años,
                  pero pueden mantenerse como titulares independientes.
                  <br />
                  <br />
                  - No cubre preexistencias.
                  <br />
                  <br />- Debes completar una Declaración Personal de Salud
                  para poder avanzar en la contratación. Aplican exclusiones.
                </p>
              </div>
            </div>
            <div id="bcaccidentes" className="container tab-pane fade">
              <div className="bcparrafo col card">
                <p className="srtitulop">Condiciones Accidentes:</p>
                <p className="bcparrafo3">
                  <b>Aplica a Sanatorio Seguro Accidentes</b><br /><br />
                  <b>- Seguro para menores de 25 años</b>, con Sistema Previsional de Salud.<br /><br />
                  - Cubre accidentes dentro y fuera del establecimiento educacional.<br /><br />
                  - No tiene copago mínimo, ni tope por evento.<br /><br />
                  - Puedes incorporar a los padres y abuelos para que hagan uso del beneficio de consulta médica de urgencia. (*)
                </p>
              </div>
            </div>
            <div id="concologico" className="container tab-pane fade">
              <div className="bcparrafo col card">
                <p className="srtitulop">Condiciones Oncológico:</p>
                <p className="bcparrafo3">
                  - Debes tener una afiliación vigente con Sistema Previsional de Salud.
                  <br />
                  <br />
                  - Puedes contratar Sanatorio Seguro Alto Costo si eres mayor de 18 años y menor de 70 años.
                  <br />
                  <br />
                  - Para incorporar como carga a tu pareja (cónyuge/conviviente civil) debe también ser mayor de 18 años y menor a 70 años.
                  <br />
                  <br />
                  - Para incorporar como carga a tus hijos deben tener, al menos, 14 días de vida y ser menores de 23 años. No puedes asegurar a tus padres o abuelos como cargas, pero ellos pueden contratar el seguro como titulares independientes.
                  <br />
                  <br />
                  - Tanto tú como tu cónyuge (en caso de ser carga) podrán permanecer de forma vitalicia, los hijos que sean carga podrán permanecer hasta antes de los 24 años, en la póliza familiar, pero podrían permanecer como titulares independientes. Esta condición se permite siempre que la póliza se encuentre vigente y el pago de las primas se encuentren al día.
                  <br />
                  <br />
                  - No cubre preexistencias.
                  <br />
                  <br />
                  - Debes completar una Declaración Personal de Salud para poder avanzar en la contratación. Aplican exclusiones, que puedes revisar en pie de página.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
);

};
export default CondicionesSeguros;
