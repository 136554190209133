const DEFAULT_FAQS_TO_SHOW = 2;
const FAQs = ({ data, index, watchAllFAQs}) => {
  const {
    id,
    nombre,
    descripcion
  } = data;

  if (index > DEFAULT_FAQS_TO_SHOW && !watchAllFAQs) {
    return null;
  }
  return (
    <div className="accordion-item border-0" key={"TNP" + id}>
        <h2 className="accordion-header" id={id.toString()}>
            <button id={"FAQ" + id.toString()} className="accordion-button btn btn-block collapsed tnpacc" data-toggle="collapse" data-target={'#l'+id.toString()} aria-expanded="false" aria-controls={id.toString()}>
            <span className="tnpnombre">{nombre}</span>
            </button>
        </h2>
        <div id={"l"+id.toString()} className="accordion-collapse collapse" aria-labelledby={id.toString()}>
            <div className="accordion-body" style={{whiteSpace: "pre-line"}}>
            {descripcion}
            </div>
        </div>
    </div>
  );
};
export default FAQs;