import '../Styles/JustoNecesitas.css'
import isapre from "../imagenes/JustoNecesitas/isapre.png" 
import validochile from "../imagenes/JustoNecesitas/validochile.png" 
import contratanecesitas from "../imagenes/JustoNecesitas/contratanecesitas.png" 
import contrataminutos from "../imagenes/JustoNecesitas/contrataminutos.png"
import Aos from "aos"
import "aos/dist/aos.css"
import React from 'react'


const JustoNecesitas = () => {
  React.useEffect(() => {
    Aos.init({duration: 2000});
  
  }, []);

    return (
      
      <div data-aos="fade-up" id="jn">
        <div className="mt-4">
          <br />
          <h1 className="titulos">Protección única en Concepción</h1>
          <h6 className="subtitulos">Sanatorio Alemán y MetLife, juntos para cuidar la salud de los que más quieres</h6>
          <br />
          <section className="container">
            <div className="row row-cols-2 row-cols-md-4 mb-4 text-center">
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div>
                    <br />
                    <img src={isapre} id="jnimg1" className="img-fluid" alt="" />
                  </div>
                  <div>
                    <h5 className="jntitulos">Beneficios exclusivos en farmacia según tu plan.</h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div>
                    <br />
                    <br />
                    <img
                      src={contratanecesitas}
                      id="jnimg2"
                      className="img-fluid" alt=""
                    />
                  </div>
                  <div>
                    <h5 className="jntitulos">Planes para Fonasa e Isapre.</h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div id="jndimg2">
                    <img
                      src={contrataminutos}
                      id="jnimg3"
                      className="img-fluid" alt=""
                    />
                  </div>
                  <div>
                    <h5 className="jntitulos">Contrata 100% online.</h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div id="jndimg3">
                    <img src={validochile} id="jnimg4" className="img-fluid " alt="" />
                  </div>
                  <div>
                    <h5 className="jntitulos">Elije un plan a tu medida.</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="text-center">
          <a className="btn btn-lg btn-primary animate__animated animate__bounce plboton" href="#sr">Ver planes</a>
        </div>
        <span>&nbsp;</span>
        </div>
        <span>&nbsp;</span>
      </div>      
    );
}

export default JustoNecesitas
