import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";

export function* watcherInsuranceFAQ() {
  yield takeLatest("INSURANCE_FAQ_REQUEST", getInsuranceFAQ);
}

function fetchInsuranceFAQ() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_URI_SERVER}/api/Public/Catalogos/CatalogosByTipo?IdTipoCatalogo=2&sitio=2`,
    params: {
      sitio:2
    }
  });
}

function* getInsuranceFAQ() {
  try {
    const response = yield call(fetchInsuranceFAQ);

    const data = response.data.map((dataAux) => {
      const { id, nombre, descripcion } = dataAux;
      //const id = id;
      //const value = nombreTipoSeguro;
      //const isSelected = true;
      return { id, nombre, descripcion };
    });
    yield put({ type: "INSURANCE_FAQ_SUCCESS", data });
  } catch (error) {
    yield put({ type: "INSURANCE_FAQ_FAILURE", error });
  }
}
